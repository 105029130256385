// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-references-js": () => import("./../../../src/pages/about/references.js" /* webpackChunkName: "component---src-pages-about-references-js" */),
  "component---src-pages-coding-js": () => import("./../../../src/pages/coding.js" /* webpackChunkName: "component---src-pages-coding-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-laughs-js": () => import("./../../../src/pages/laughs.js" /* webpackChunkName: "component---src-pages-laughs-js" */),
  "component---src-pages-life-js": () => import("./../../../src/pages/life.js" /* webpackChunkName: "component---src-pages-life-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-old-js": () => import("./../../../src/pages/old.js" /* webpackChunkName: "component---src-pages-old-js" */),
  "component---src-pages-works-js": () => import("./../../../src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */)
}

